import React from 'react'
import styled from 'styled-components';
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    RedditShareButton,
    RedditIcon
} from 'react-share'

const ShareButtons = ({ title, url, twitterHandle, tags, related, description }) => {

    return (
        <Container >
            <FacebookShareButton url={url} quote={title} >
                <FacebookIcon size={40} round={true} />
            </FacebookShareButton>

            <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags} related={related}>
                <TwitterIcon size={40} round={true} />
            </TwitterShareButton>

            <LinkedinShareButton url={url} title={title} source={url} summary={description} >
                <LinkedinIcon size={40} round={true} />
            </LinkedinShareButton>

            <RedditShareButton url={url} title={title} >
                <RedditIcon size={40} round={true} />
            </RedditShareButton>

            <WhatsappShareButton url={url} title={title}>
                <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>
        </Container>
    )

}
export default ShareButtons

const Container = styled.div`
    
    &:hover {
        transform: scale(1.1)
    }

    @media screen and (min-width: 600px) {
        display: flex;
    justify-content: center;
    }
`