import React from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components';
import { Button } from '../components/Button'
import ShareButtons from "../components/share/ShareButtons";
import { Disqus } from 'gatsby-plugin-disqus';


import Layout from '../components/layout'
import SEO from '../components/seo'

const BlogPostTemplate = ({ data, location }) => {


  const post = data ? data.markdownRemark : null;
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const twitterHandle = 'TripleaimSoftw1'
  const related = ["TripleaimSoftw1", "Okichibrahim"]
  const disqusConfig = {
    url: location.href,
    identifier: data.markdownRemark.id,
    title: post.frontmatter.title
  }

  //console.log("this is location", location)


  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        keywords={post.frontmatter.keywords}
        image={post.frontmatter.image}
        siteUrl={location.href}
        canonical={location.href}
      >
      </SEO>
      <Container>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 itemProp="headline" css={`color: #06546B`}>{post.frontmatter.title}</h1>
            <p css={`font-size: clamp(0.5rem,1vw, 0.75rem);`}>Posted By Dr. Ibrahim Okich on {post.frontmatter.date}</p>
          </header>
          <section
            css={`list-style-position: inside;`}
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
          <hr />
          <div>Share it on
            <ShareButtons
              title={post.frontmatter.title}
              url={location.href}
              tags={post.frontmatter.tags}
              twitterHandle={twitterHandle}
              related={related}
              description={post.frontmatter.description}

            /></div>

          {/* <footer>
          <Bio />
        </footer> */}
          <SubscribeContainer>
            <ContainerP1>Want to understand more on the future of Health Technology? </ContainerP1>
            <ContainerP2>Get our free email series </ContainerP2>
            <form name="subscribe" method="POST" data-netlify="true" netlify-honeypot="bot-field">
              <input type="hidden" name="form-name" value="subscribe" />
              <FormWrap>
                <label htmlFor="email">
                  <input type="email" placeholder="Enter Your Email" id="email" name="email" />
                </label>
                <Button as="button" type="submit" primary="true" css={`width: 100%; height: 50px;`}>Get the Free Newsletter</Button>
              </FormWrap>
            </form>
          </SubscribeContainer>
        </article>
        <nav className="blog-post-nav">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              textAlign: `center`,
              padding: 0,
            }}
          >
            <li css={`background-color: #E5E5E5; width: auto; height: auto; border-radius: 10px; margin-bottom: 3px; font-size: 20px`}>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li css={`background-color: #E5E5E5; width: auto; height: auto; border-radius: 10px;font-size: 20px`}>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
        <Disqus config={disqusConfig} />
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

const Container = styled.div`
  margin-top: 7rem;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  

  @media screen and (min-width: 600px) {
    max-width: 50%;

  @media screen and (min-width: 1000px) {
    max-width: 35%;
}
`
const SubscribeContainer = styled.div`
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1000px) {
       // max-width: 50%;
    }
`

const ContainerP1 = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    padding: 2rem 1rem 0rem 1rem;
    font-weight: bold;
`
const ContainerP2 = styled.p`
    font-size: clamp(1rem,2vw, 1.25rem);
    text-align: center;
    font-weight: bold;
`
const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
   

 input {
     padding: 1rem 1,5rem;
     outline: none;
     width: 100%;
     height: 50px;
     border-radius: 20px;
     border: 2px solid #71C4DE;
     margin-bottom: 1rem;
     text-align: center;
 }
`


export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        keywords
        image 
     }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
